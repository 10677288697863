(function () {
  import(/* webpackChunkName: "application-code" */ './initiateSDK')
    .then((module) => {
       (window as any).ICSalesSDK = module.default;
      if ((window as any).onICSalesSDKReady) {
        (window as any).onICSalesSDKReady();
      } else {
        console.error(
          `please assign window object onICSalesSDKReady function for ${window.location.host}`
        );
      }
    })
    .catch((error) => {
      console.log('Error loading icSalesSDK : ', error);
    });
})();

